/* stylelint-disable */
/* 
  Note that these styles are necessary in order to override underlying 
  style/themes of library used to display clock
*/
.odometer.odometer-auto-theme,
.odometer.odometer-theme-custom {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-custom .odometer-digit {
  display: inline-block;
  position: relative;
  margin: 3px;
  background-color: black;

  padding: 0 0.15em;
  border-radius: 0.1em 0.1em;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-custom .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-custom .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-custom .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-custom .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-custom .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-custom
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 12s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  transition: transform 12s;
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-custom {
  border-radius: 0.34em;
  font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
  padding: 0.15em;
  background: white;
  color: white;
  font-size: 90px;
  font-weight: 450;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-custom .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  /* OPTIONS FOR TRANSITION TIMING FUNCTION:  */
  transition-timing-function: ease-in-out;
  /* transition-timing-function: linear; */
  /* transition-timing-function: cubic-bezier(.18,0,.86,.94) */
}

.odometer-formatting-mark {
  color: var(--PRIMARY_RED);
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-custom.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition-duration: 12s !important;
  -moz-transition-duration: 12s !important;
  -ms-transition-duration: 12s !important;
  -o-transition-duration: 12s !important;
  transition-duration: 12s !important;
}

.odometer .odometer-inside:before {
  content: '$';
  color: var(--PRIMARY_RED) !important;
  margin-right: 20px;
}

/* 
  NOTE: App is specifically styled for LED screen resolution provided by Adtronics (except width 1680px)
  LED screen resolutions: 
  960w x 480h pixels
  704w x 480h pixels
  480w x 320h pixels
  480w x 240h pixels
*/

/* 
  For width 1650px for testing purposes on mac
*/
@media only screen and (max-width: 1680px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-custom {
    padding: 2px;
  }

  .odometer .odometer-inside:before {
    margin-right: 10px;
  }
}

/* 
  For LED screen size of 960w x 320h pixels
*/
@media only screen and (max-width: 960px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-custom {
    padding: 2px;
    font-size: 48px;
  }

  .odometer .odometer-inside:before {
    margin-right: 7px;
  }
}

/* 
  For LED screen size of 704h x 480w pixels
*/
@media only screen and (max-width: 704px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-custom {
    padding: 2px;
    font-size: 37.5px;
  }

  .odometer .odometer-inside:before {
    margin-right: 5px;
  }

  .odometer.odometer-auto-theme .odometer-digit,
  .odometer.odometer-theme-custom .odometer-digit {
    margin: 1px;
  }
}

/* 
  For LED screen width of 480w pixels
*/
@media only screen and (max-width: 480px) {
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-custom {
    padding: 2px;
    font-size: 24px;
  }
}
