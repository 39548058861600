.proudly-supported-by-container {
  width: 75%;
  margin: auto;
}

.proudly-supported-by-text {
  background-color: var(--PRIMARY_RED);
  font-size: var(--SUB_HEADER_FONT_SIZE);
  position: absolute;
  border-radius: 0 50px 50px 0;
  letter-spacing: 6px;
  top: 7vh;
  color: white;
  padding: 9px;
  width: 65vw;
  font-weight: 450;
}

.proudly-supported-by-text-fr {
  width: 72vw;
  letter-spacing: 4px;
}

.proudly-supported-by-text-cn {
  letter-spacing: 15px;
  width: 27vw;
}

.proudly-supported-by-text-cn_yue {
  letter-spacing: 15px;
  width: 27vw;
}

.proudly-supported-by-text-tl {
  width: 80vw;
}

.proudly-supported-by-text-pa {
  width: 80vw;
  letter-spacing: 4px;
}

.proudly-supported-by-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.proudly-supported-by-image-width {
  width: 70vw;
  max-height: 60vh;
}

/* 
  For LED screen size of 960w x 480h pixels
*/
@media only screen and (max-width: 960px) {
  .proudly-supported-by-text-en {
    letter-spacing: 3px;
    width: 70vw;
  }

  .proudly-supported-by-text-fr {
    letter-spacing: 1px;
  }

  .proudly-supported-by-text-cn {
    letter-spacing: 7px;
    width: 30vw;
  }

  .proudly-supported-by-text-cn_yue {
    letter-spacing: 7px;
    width: 30vw;
  }

  .proudly-supported-by-text-tl {
    width: 80vw;
    letter-spacing: 2px;
  }

  .proudly-supported-by-text-pa {
    width: 91vw;
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 704w x 480h pixels
*/
@media only screen and (max-width: 704px) {
  .proudly-supported-by-text-en {
    letter-spacing: 0.75px;
    width: 75vw;
  }

  .proudly-supported-by-text-fr {
    width: 94vw;
  }

  .proudly-supported-by-text-cn {
    letter-spacing: 4px;
  }

  .proudly-supported-by-text-cn_yue {
    letter-spacing: 4px;
  }

  .proudly-supported-by-text-tl {
    width: 95vw;
    letter-spacing: 0.5px;
  }

  .proudly-supported-by-text-pa {
    letter-spacing: 0.25px;
    width: 96vw;
  }
}

/* 
  For LED screen size of 480w x 320h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 320px) {
  .proudly-supported-by-text-en {
    width: 74vw;
    letter-spacing: 0.5px;
  }

  .proudly-supported-by-text-fr {
    text-align: left;
    letter-spacing: 0.25px;
    width: 90vw;
  }

  .proudly-supported-by-text-cn {
    width: 32vw;
    letter-spacing: 3px;
  }

  .proudly-supported-by-text-cn_yue {
    width: 32vw;
    letter-spacing: 3px;
  }

  .proudly-supported-by-text-tl {
    font-size: 10.75px;
  }

  .proudly-supported-by-image-width {
    width: 80vw;
  }

  .proudly-supported-by-text-pa {
    letter-spacing: 0.19px;
    width: 96vw;
  }
}

/* 
  For LED screen size of 480w x 240h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 240px) {
  .proudly-supported-by-text-en {
    width: 74vw;
    font-size: var(--SUB_HEADER_FONT_SIZE);
    letter-spacing: 0.5px;
    top: 5vh;
  }

  .proudly-supported-by-image-width {
    width: 75vw;
  }
}
