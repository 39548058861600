.main-container {
  text-align: center;
  padding: 6.35vh 5.2vw 0px 5.2vw;
}

.debt-clock-share-taxpayer-logo-container {
  margin: 50px 0 25px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.debt-clock-federal-container {
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 15px;
  padding: 0 0 20px 0;
}

.debt-clock-federal-text {
  color: var(--PRIMARY_RED);
  font-size: var(--HEADER_FONT_SIZE);
  background: white;
  margin-bottom: 0;
}

.debt-clock-federal-text-en {
  width: 39vw;
  margin-top: -4vh;
  margin-left: 48vw;
}

.debt-clock-federal-text-fr {
  margin-top: -4vh;
  width: 45vw;
  margin-left: 42vw;
}

.debt-clock-federal-text-cn {
  width: 24vw;
  margin-top: -5vh;
  margin-left: 62.5vw;
}

.debt-clock-federal-text-cn_yue {
  width: 24vw;
  margin-top: -5vh;
  margin-left: 62.5vw;
}

.debt-clock-federal-text-tl {
  width: 44vw;
  margin-top: -4vh;
  margin-left: 43vw;
}

.debt-clock-federal-text-pa {
  width: 25vw;
  margin-top: -5vh;
  margin-left: 61vw;
}

.debt-clock-share {
  border-style: solid;
  border-color: #e0e0e0;
  border-radius: 15px;
  padding: 0 0 20px 0;
}

.debt-clock-share-text {
  background: white;
  color: var(--PRIMARY_RED);
  margin-bottom: 0;
  font-size: var(--HEADER_FONT_SIZE);
}

.debt-clock-share-text-en {
  width: 20.8vw;
  margin-top: -40px;
  margin-left: 21vw;
}

.debt-clock-share-text-fr {
  margin-top: -40px;
  width: 19vw;
  margin-left: 23vw;
}

.debt-clock-share-text-cn {
  margin-top: -44px;
  width: 12.5vw;
  margin-left: 29vw;
}

.debt-clock-share-text-cn_yue {
  margin-top: -44px;
  width: 12.5vw;
  margin-left: 29vw;
}

.debt-clock-share-text-tl {
  width: 34vw;
  margin-top: -40px;
  margin-left: 8vw;
}

.debt-clock-share-text-pa {
  width: 13vw;
  margin-top: -45px;
  margin-left: 28vw;
}

.taxpayer-logo {
  margin: auto;
}

.logo-width {
  width: 40.25vw;
}

.supported-by-container {
  margin-top: 5.29vh;
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.supported-by-text {
  width: 31.25vw;
  margin: -25px 0px;
  background-color: var(--PRIMARY_RED);
  z-index: 10;
  position: relative;
  border-radius: 0 50px 50px 0;
  padding: 10px 2px;
  color: white;
  font-weight: 400;
  font-size: var(--SUB_HEADER_FONT_SIZE);
  letter-spacing: 6px;
}

.supported-by-text-cn {
  width: 25.25vw;
  letter-spacing: 15px;
}

.supported-by-text-cn_yue {
  width: 25.25vw;
  letter-spacing: 15px;
}

.supported-by-text-tl {
  width: 39vw;
}

.sign-petition-text-container {
  text-align: right;
}

.sign-petition-text {
  font-size: var(--HEADER_FONT_SIZE);
}

.sign-petition-bold {
  font-weight: bold;
}

/* 
  NOTE: App is specifically styled for LED screen resolution provided by Adtronics (except for styling for 1680px width)
  LED screen resolutions: 
  960w x 480h pixels
  704w x 480h pixels
  480w x 320h pixels
  480w x 240h pixels
*/

/* 
  Width of 1650px for testing purposes locally
*/
@media only screen and (max-width: 1680px) and (max-height: calc(0.7 * 100vw)) {
  .main-container {
    padding: 10vh 2.75vw 0px 2.75vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 10vh 0 8.36vh 0;
  }

  .debt-clock-federal-text-en {
    width: 38.75vw;
    margin-top: -4vh;
    margin-left: 52vw;
  }

  .debt-clock-federal-text-cn {
    margin-left: 67vw;
  }

  .debt-clock-federal-text-tl {
    width: 43.75vw;
    margin-top: -4vh;
    margin-left: 47vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-cn {
    margin-left: 31vw;
  }

  .debt-clock-share-text-tl {
    width: 34vw;
    margin-top: -40px;
    margin-left: 9vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -17px 0px;
  }
}

/* 
  Width of 1650px for testing purposes locally
*/
@media only screen and (max-width: 1680px) and (max-height: calc(0.51 * 100vw)) {
  .main-container {
    padding: 6.47vh 2.75vw 0px 2.75vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 8.47vh 0 6.36vh 0;
  }

  .debt-clock-federal-text-en {
    width: 38.75vw;
    margin-top: -4vh;
    margin-left: 52vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -4vh;
    width: 48vw;
    margin-left: 43.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 25vw;
    margin-top: -5vh;
    margin-left: 66vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -4.5vh;
    margin-left: 22.75vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-pa {
    width: 13vw;
    margin-top: -45px;
    margin-left: 30.5vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -17px 0px;
  }
}

/* 
  For LED screen size of 960w x 480h pixels
*/
@media only screen and (max-width: 960px) {
  .main-container {
    padding: 35px 25px 0px 25px;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 25px 0 10px 0;
  }

  .debt-clock-federal-text-en {
    width: 41.5vw;
    margin-top: -4vh;
    margin-left: 50.25vw;
  }

  .debt-clock-federal-text-fr {
    width: 48vw;
    margin-left: 43.5vw;
    margin-top: -4vh;
  }

  .debt-clock-federal-text-cn {
    width: 22.75vw;
    margin-top: -5.25vh;
    margin-left: 68.8vw;
  }

  .debt-clock-federal-text-cn_yue {
    width: 22.75vw;
    margin-top: -5.25vh;
    margin-left: 68.8vw;
  }

  .debt-clock-federal-text-tl {
    width: 46.75vw;
    margin-top: -4vh;
    margin-left: 44.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 27vw;
    margin-left: 63vw;
  }

  .debt-clock-share-text-en {
    margin-top: -4.5vh;
    margin-left: 23vw;
    width: 21.3vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -4.5vh;
    margin-left: 23.75vw;
    width: 20vw;
  }

  .debt-clock-share-text-cn {
    margin-top: -4.75vh;
    margin-left: 30.75vw;
    width: 13.3vw;
  }

  .debt-clock-share-text-cn_yue {
    margin-top: -4.75vh;
    margin-left: 30.75vw;
    width: 13.3vw;
  }

  .debt-clock-share-text-tl {
    margin-top: -4.5vh;
    margin-left: 7.25vw;
    width: 36.5vw;
  }

  .debt-clock-share-text-pa {
    margin-top: -5.5vh;
    margin-left: 28vw;
    width: 14.5vw;
  }

  .supported-by-text {
    width: 35vw;
    letter-spacing: 4px;
    padding: 8px 3px;
    margin: -18px 0px;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 15px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 15px;
  }

  .supported-by-text-tl {
    width: 40vw;
  }

  .supported-by-text-pa {
    width: 30vw;
  }
}

/* 
  For LED screen size of 704h x 480w pixels
*/
@media only screen and (max-width: 704px) {
  .main-container {
    padding: 40px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 40px 0 12.5px 0;
  }

  .debt-clock-federal-text-en {
    width: 42vw;
    margin-top: -11.5vh;
    margin-left: 50vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -11.5vh;
    width: 48vw;
    margin-left: 43.5vw;
  }

  .debt-clock-federal-text-cn {
    margin-top: -12vh;
    width: 23vw;
    margin-left: 68.5vw;
  }

  .debt-clock-federal-text-cn_yue {
    margin-top: -12vh;
    width: 23vw;
    margin-left: 68.5vw;
  }

  .debt-clock-federal-text-tl {
    width: 46.75vw;
    margin-top: -11.5vh;
    margin-left: 44.5vw;
  }

  .debt-clock-federal-text-pa {
    width: 27vw;
    margin-top: -12vh;
    margin-left: 64vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 30px 0;
  }

  .debt-clock-share {
    padding: 40px 0 12.5px 0;
  }

  .debt-clock-share-text-en {
    width: 22vw;
    margin-top: -11.5vh;
    margin-left: 21.75vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-fr {
    margin-left: 23.25vw;
    width: 20vw;
    margin-top: -11.5vh;
  }

  .debt-clock-share-text-cn {
    margin-left: 29.75vw;
    width: 13.75vw;
    margin-top: -12vh;
  }

  .debt-clock-share-text-cn_yue {
    margin-left: 29.75vw;
    width: 13.75vw;
    margin-top: -12vh;
  }

  .debt-clock-share-text-tl {
    width: 36.5vw;
    margin-top: -11.5vh;
    margin-left: 7vw;
    font-size: var(--HEADER_FONT_SIZE);
  }

  .debt-clock-share-text-pa {
    width: 14.5vw;
    margin-top: -12vh;
    margin-left: 28.75vw;
  }

  .sign-petition-text {
    font-size: var(--HEADER_FONT_SIZE);
  }

  .supported-by-text {
    width: 35vw;
    font-size: var(--SUB_HEADER_FONT_SIZE);
    letter-spacing: 1px;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-tl {
    width: 45vw;
  }

  .supported-by-text-pa {
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 480w x 320h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 320px) {
  .main-container {
    padding: 25px 15px 0px 15px;
  }

  .debt-clock-federal-container {
    padding: 40px 0 6px 0;
  }

  .debt-clock-federal-text-en {
    width: 43vw;
    margin-top: -16.5vh;
    margin-left: 45.5vw;
  }

  .debt-clock-federal-text-fr {
    width: 51vw;
    margin-left: 37vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-cn {
    width: 23.5vw;
    margin-left: 65vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-cn_yue {
    width: 23.5vw;
    margin-left: 65vw;
    margin-top: -16.5vh;
  }

  .debt-clock-federal-text-tl {
    width: 48.5vw;
    margin-top: -16.5vh;
    margin-left: 39.75vw;
  }

  .debt-clock-federal-text-pa {
    width: 28vw;
    margin-top: -17vh;
    margin-left: 60.5vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 25px 0 20px 0;
  }

  .debt-clock-share {
    padding: 40px 0 6px 0;
  }

  .debt-clock-share-text-en {
    width: 23vw;
    margin-top: -16.5vh;
    margin-left: 18vw;
  }

  .debt-clock-share-text-fr {
    width: 21.75vw;
    margin-top: -16.5vh;
    margin-left: 19vw;
  }

  .debt-clock-share-text-cn {
    width: 14vw;
    margin-top: -16.5vh;
    margin-left: 27vw;
  }

  .debt-clock-share-text-cn_yue {
    width: 14vw;
    margin-top: -16.5vh;
    margin-left: 27vw;
  }

  .debt-clock-share-text-tl {
    width: 38.25vw;
    margin-top: -16.5vh;
    margin-left: 3.25vw;
  }

  .debt-clock-share-text-pa {
    width: 15vw;
    margin-top: -17vh;
    margin-left: 26.25vw;
  }

  .supported-by-text {
    width: 40vw;
    letter-spacing: 1.5px;
    padding: 6px 2px;
    margin: -15px 0px;
  }

  .supported-by-text-tl {
    width: 50vw;
  }

  .supported-by-text-cn {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-cn_yue {
    width: 25vw;
    letter-spacing: 10px;
  }

  .supported-by-text-pa {
    letter-spacing: 3px;
  }
}

/* 
  For LED screen size of 480w x 240h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 240px) {
  .main-container {
    padding: 16px 15px 0px 15px;
  }

  .debt-clock-federal-text-en {
    margin-top: -21.25vh;
    margin-left: 51.75vw;
    width: 36.5vw;
  }

  .debt-clock-federal-text-fr {
    margin-top: -21.25vh;
    width: 41.75vw;
    margin-left: 45.75vw;
  }

  .debt-clock-federal-text-cn {
    width: 20vw;
    margin-top: -21.75vh;
    margin-left: 68vw;
  }

  .debt-clock-federal-text-cn_yue {
    width: 20vw;
    margin-top: -21.75vh;
    margin-left: 68vw;
  }

  .debt-clock-federal-text-tl {
    margin-top: -21.25vh;
    margin-left: 48.5vw;
    width: 40.75vw;
  }

  .debt-clock-federal-text-pa {
    margin-top: -22vh;
    margin-left: 64.75vw;
    width: 23.5vw;
  }

  .debt-clock-share-taxpayer-logo-container {
    margin: 12px 0 6px 0;
  }

  .debt-clock-share-text-en {
    margin-top: -21.25vh;
    margin-left: 22.5vw;
    width: 19vw;
  }

  .debt-clock-share-text-fr {
    margin-top: -21.25vh;
    margin-left: 22.75vw;
    width: 17.75vw;
  }

  .debt-clock-share-text-cn {
    margin-top: -21.75vh;
    margin-left: 29vw;
    width: 12vw;
  }

  .debt-clock-share-text-cn_yue {
    margin-top: -21.75vh;
    margin-left: 29vw;
    width: 12vw;
  }

  .debt-clock-share-text-tl {
    margin-top: -21.25vh;
    margin-left: 10.75vw;
    width: 31.5vw;
  }

  .debt-clock-share-text-pa {
    margin-top: -22vh;
    margin-left: 28.65vw;
    width: 12.75vw;
  }

  .supported-by-text {
    padding: 3px 1px;
    margin: -10px 0px;
  }
}
