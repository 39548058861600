body {
  font-family: 'proxima-nova', 'Helvetica Neue', sans-serif;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 23vh;
  overflow: hidden;
  background-color: black;
  color: white;
}

.ad-slider {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.ad-text {
  position: relative;
  margin: 0px 96px;
  font-size: var(--SUB_HEADER_FONT_SIZE);
  font-weight: 450;
  width: 269px;
  word-wrap: break-word;
  white-space: normal;
}

.center {
  margin: auto;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100vw);
  }
}

/* 
  NOTE: App is specifically styled for LED screen resolution provided by Adtronics
  LED screen resolutions: 
  960w x 480h pixels
  704w x 480h pixels
  480w x 320h pixels
  480w x 240h pixels
*/

/* 
  For LED screen size of 960w x 480h pixels
*/
@media only screen and (max-width: 960px) {
  .container {
    height: 120px;
  }

  .ad-text {
    width: 154px;
  }
}

/* 
  For LED screen size of 704w x 480h pixels
*/
@media only screen and (max-width: 704px) {
  .container {
    height: 140px;
  }

  .ad-text {
    margin: 0px 20px;
    font-size: var(--SUB_HEADER_FONT_SIZE);
    width: 160px;
  }
}

/* 
  For LED screen size of 480w x 320h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 320px) {
  .container {
    height: 93px;
  }

  .ad-text {
    width: 110px;
  }
}

/* 
  For LED screen size of 480w x 240h pixels
*/
@media only screen and (max-width: 480px) and (max-height: 240px) {
  .container {
    height: 70px;
  }

  .ad-text {
    width: 110px;
  }
}
